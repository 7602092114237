<template>
  <div class="mt-3">
    <div class="row mx-0">
      <Card
        v-if="$store.getters['loadingQueue/showLoadingBar']()"
        class="w-100"
      >
        <b-skeleton-table :columns="1" :rows="5" />
      </Card>
      <div
        v-show="
          !$store.getters['loadingQueue/showLoadingBar']() &&
          elements &&
          elements.length > 0
        "
        class="col-12 p-0"
      >
        <ProcessTableEntry
          ref="processTableEntry"
          v-model="elements"
          @show-export-modal="showExportModal"
          @show-test-workflow-modal="showTestWorkflowModal"
          @reload-workflows="loadWorkflows"
          @show-versions="onShowVersions"
          @open-requeue-modal="onOpenRequeueModal"
        />
      </div>
    </div>

    <!--  START: Test workflow sidebar  -->
    <StartSidebar
      :process="testWorkflowCurrentWorkflow"
      :workflow-show-sidebar="testWorkflowShowSidebar"
      :test-workflow-start="true"
      @close="testWorkflowShowSidebar = false"
    />
    <!--  END: Test workflow sidebar  -->

    <BackupModal
      v-model="showVersions"
      :object="selectedProcess?.workflow"
      classname="Workflow"
    />

    <v-dialog
      v-if="requeueModal"
      ref="requeueModal"
      v-model="requeueModal"
      transition="dialog-bottom-transition"
      content-class="bg-white requeue-modal"
      onclose="this.resetRequeueFormData"
    >
      <Card class="p-6">
        <FormHelper
          v-model="requeueFormData"
          :form="requeueForm"
          :config="requeueFormConfig"
          @change="onRequeueFormDataChange"
        />
        <div class="d-flex justify-content-end">
          <button
            class="btn btn-primary mt-5"
            :class="{ 'spinner spinner-left': isLoadingRequeueCount }"
            :disabled="requeueFormButtonDisabled || isLoadingRequeueCount"
            @click="onSubmit"
          >
            {{ $t("workflowDesigner.requeue") }}
            <span v-if="requeueCount >= 0" class="badge badge-light ml-1">
              {{ requeueCount }}
            </span>
          </button>
        </div>
      </Card>
    </v-dialog>
  </div>
</template>

<script>
import FlowTableEntries from "@/components/Workflows/Designer/Components/flowTableEntries";
import {
  EMPTY_ENTRY_STATE,
  INSERT_TABLE_ENTRIES,
  SET_TABLE_ENTRIES
} from "@/core/services/store/process.module";
import { mapGetters } from "vuex";
import { formatDate } from "@/components/Tools/helperFunctions";
import ProcessTableEntry from "@/components/Workflows/Designer/Components/ProcessTableEntry/ProcessTableEntry.vue";
import WorkflowManager from "@/components/Workflows/workflowManager";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";
import BackupModal from "@/components/Backup/BackupModal.vue";
import Card from "@/components/Tools/Card/Card.vue";
import StartSidebar from "@/components/Tools/Workflows/StartSidebar.vue";
import FormHelper from "@/components/Tools/FormHelper/FormHelper.vue";
import ProcessIteration from "@/components/Workflows/processIteration";
import { useCurrentProjectFilter } from "@/composables/useCurrentProjectFilter";

export default {
  components: {
    FormHelper,
    StartSidebar,
    Card,
    BackupModal,
    ProcessTableEntry
  },
  props: {
    nested: {
      required: false,
      type: Boolean,
      default: null
    }
  },
  data() {
    return {
      selectedProcess: null,
      showVersions: false,
      workflows: [],
      workflowsForTable: [],
      // Meta info
      meta: {},
      searchTitle: "",
      testWorkflowShowSidebar: false,
      form: [
        {
          type: "code",
          name: "parameters",
          lang: "json"
        }
      ],
      formCodeView: [
        {
          type: "code",
          name: "parameters",
          lang: "json",
          disabled: true
        }
      ],
      formConfig: {
        labelStacked: true
      },
      testWorkflowCurrentWorkflow: null,
      requeueModal: false,
      requeueFormConfig: {
        title: this.$t("workflowDesigner.requeue"),
        enableVariables: false,
        fieldColClass: "col-9",
        fieldColClassXl: "col-9",
        labelColClass: "col-3"
      },
      requeueForm: [
        {
          type: "multiselect",
          label: this.$t("workflowDesigner.status"),
          name: "statuses",
          placeHolder: this.$t("workflowDesigner.selectStatus"),
          clearable: true,
          options: [
            {
              label: this.$t("reporting.processTypeAborted"),
              value: "process.aborted"
            },
            {
              label: this.$t("reporting.processTypeError"),
              value: "process.error"
            },
            {
              label: this.$t("reporting.processTypeStopped"),
              value: "process.stopped"
            },
            {
              label: this.$t("reporting.processTypeWarning"),
              value: "process.warning"
            }
          ],
          validations: {
            required: true,
            minLength: 1
          }
        },
        {
          type: "datetime",
          name: "from",
          label: this.$t("workflowDesigner.from")
        },
        {
          type: "datetime",
          name: "to",
          label: this.$t("workflowDesigner.to")
        }
      ],
      requeueFormData: {
        statuses: null,
        from: null,
        to: null
      },
      requeueFormButtonDisabled: true,
      requeueCount: -1,
      isLoadingRequeueCount: false,
      debounceTimer: null
    };
  },
  computed: {
    ...mapGetters(["selectedPresetVersion", "selectedProject"]),
    ...mapGetters("route", ["requestParams"]),
    ...mapGetters("processTableEntries", ["processTableEntriesConfig"]),
    ...mapGetters("instance", ["instance"]),
    elements: {
      get() {
        return this.$store.getters.processTableEntries;
      },
      set(value) {
        this.$store.dispatch(INSERT_TABLE_ENTRIES, value);
      }
    }
  },
  watch: {
    nested: function () {
      this.loadWorkflows();
    },
    selectedProject: function () {
      this.loadWorkflows();
    },
    selectedPresetVersion: function () {
      this.loadWorkflows();
    },
    searchTitle: function () {
      this.workflowsForTable = this.getWorkflowsForTable(this.value);
    },
    requeueModal: function () {
      if (!this.requeueModal) this.resetRequeueFormData();
    },
    requeueFormData: function () {
      this.requeueFormButtonDisabled = !Object.keys(
        this.requeueFormData.statuses || {}
      ).length;
    }
  },
  mounted() {
    this.$store.dispatch(EMPTY_ENTRY_STATE);
    this.loadWorkflows();
  },
  methods: {
    formatDate,
    loadWorkflows() {
      this.workflows = [];
      this.workflowsForTable = [];

      if (this.instance?.isPresetInstance && !this.selectedPresetVersion) {
        return;
      }

      addEventToLoadingQueue({ key: "loadWorkflows" });
      let params = this.requestParams();
      const { filter } = useCurrentProjectFilter();
      // Load more than 15 workflows
      params.noPagination = true;

      FlowTableEntries.getAll(params, filter.value)
        .then(response => {
          this.workflows = response.data;
          this.$store.dispatch(SET_TABLE_ENTRIES, this.workflows);
          this.meta = response.meta;
          removeEventFromLoadingQueue({ key: "loadWorkflows" });
        })
        .catch(error => {
          this.$error(error);
        });
    },
    collapseAll(elements = this.elements) {
      for (const element of elements) {
        const isCollapsed =
          this.processTableEntriesConfig?.[element.id]?.collapsed;

        if (element?.children?.length) this.collapseAll(element.children);

        if (
          (element.type === "process" && !element?.children?.length) ||
          isCollapsed
        )
          continue;
        this.$refs.processTableEntry.toggleChildren(element);
      }
    },
    collapseNone(elements = this.elements) {
      for (const element of elements) {
        const isCollapsed =
          this.processTableEntriesConfig?.[element.id]?.collapsed;

        if (element?.children?.length) this.collapseNone(element.children);

        if (
          (element.type === "process" && !element?.children?.length) ||
          !isCollapsed
        )
          continue;
        this.$refs.processTableEntry.toggleChildren(element);
      }
    },
    showExportModal(workflow) {
      this.exportWorkflow(workflow);
    },
    showTestWorkflowModal(workflow) {
      this.testWorkflowShowSidebar = true;
      this.testWorkflowCurrentWorkflow = workflow;
    },
    setSelectedProcess(process) {
      this.selectedProcess = process;
      this.selectedProcess.workflow.label = this.selectedProcess.name;
    },
    onShowVersions(process) {
      this.setSelectedProcess(process);
      this.showVersions = true;
    },
    onOpenRequeueModal(process) {
      this.setSelectedProcess(process);
      this.requeueModal = true;
    },
    onSubmit() {
      if (!this.requeueFormData.statuses) return;

      this.requeueModal = false;
      let eventParams = {
        key: "bulkRequeueJob",
        progressBarKey: null
      };

      if (this.isModal) {
        eventParams.progressBarKey = "loadingSidebar";
      }

      addEventToLoadingQueue(eventParams);

      const data = this.getRequeueFormData();
      ProcessIteration.bulkRequeue(data)
        .then(() => {
          removeEventFromLoadingQueue({
            key: eventParams.key,
            progressBarKey: eventParams.progressBarKey,
            type: "success",
            prefix: "processManager",
            name: "jobRequeued"
          });
          this.resetRequeueFormData();
        })
        .catch(error => {
          this.$error(error);
        });
    },
    onRequeueFormDataChange() {
      if (this.debounceTimer) clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(
        function (me) {
          if (!me.requeueFormData.statuses) return;
          let formData = me.getRequeueFormData();
          me.isLoadingRequeueCount = true;
          ProcessIteration.bulkRequeueCount(formData)
            .then(response => {
              me.requeueCount = response.data.count;
            })
            .catch(error => {
              me.$error(error);
            })
            .finally(() => {
              me.isLoadingRequeueCount = false;
            });
        },
        300,
        this
      );
    },
    getRequeueFormData() {
      let data = {
        process_id: this.selectedProcess.id,
        statuses: this.requeueFormData?.statuses ?? [],
        filter: []
      };
      if (this.requeueFormData?.from) {
        data.filter.push({
          key: "created_at",
          op: "greaterThanEqual",
          value: this.requeueFormData.from
        });
      }
      if (this.requeueFormData?.to) {
        data.filter.push({
          key: "created_at",
          op: "lesserThanEqual",
          value: this.requeueFormData.to
        });
      }
      return data;
    },
    resetRequeueFormData() {
      this.requeueFormData = {
        statuses: null,
        from: null,
        to: null
      };
      this.requeueCount = -1;
    },
    getWorkflowsForTable(workflows, level = 1) {
      let ret = [];
      workflows.forEach(w => {
        w.elements = [];
        if (!w.name.toLowerCase().includes(this.searchTitle.toLowerCase())) {
          return;
        }
        w.detailsShowing = false;
        w.level = level;
        ret.push(w);
        if (w.showDetails) {
          if (w.child_processes.length > 0) {
            w.child_processes[w.child_processes.length - 1].lastChild = true;
          }
          Array.prototype.push.apply(
            ret,
            this.getWorkflowsForTable(w.child_processes, level + 1)
          );
        }
      });
      return ret;
    },
    exportWorkflow(workflow) {
      const workflowId = workflow.workflow.id;
      const workflowName = workflow.name;
      this.isBusy = true;
      WorkflowManager.export(workflowId, "json")
        .then(response => {
          const blob = new Blob([JSON.stringify(response)], {
            type: "application/json"
          });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download =
            "[xentral_connect]" +
            workflowName.replace(/ /g, "_") +
            "_" +
            workflowId;
          link.click();
          URL.revokeObjectURL(link.href);
          this.$toast.fire({
            title: this.$t("workflowDesigner.workflowExportSuccess", {
              name: workflowName
            }),
            icon: "success"
          });
          this.isBusy = false;
        })
        .catch(error => {
          this.$error(error);
          this.isBusy = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.b-skeleton-text {
  height: 2rem;
  margin: 0.9rem 0;
  width: 100% !important;
}

.fa-plus-circle {
  color: $color-connect-text;
}

:deep(.requeue-modal) {
  width: 500px;
  max-width: 80%;
  // Display datetime picker
  overflow: visible;
}
</style>
